export const overlay = 'linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.6))';

export const mapPhotoSwipeImages = data =>
  data.reduce(
    (acc, cur) => ({
      small: [...acc.small, ...[cur.node.childImageSharp.small]],
      large: [
        ...acc.large,
        ...[
          {
            src: cur.node.childImageSharp.large.src,
            h: cur.node.childImageSharp.large.presentationHeight,
            w: cur.node.childImageSharp.large.presentationWidth
          }
        ]
      ]
    }),
    { small: [], large: [] }
  );
